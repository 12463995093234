/*eslint-disable */
// import SubscriptionModel from '../../Model/Subscription'
import request from '../../../Utils/curl'
import Utility from "../../../Utils/utility"

let Subscriptions = {
  /**
   * subscriptionList
   */
  async subscriptionList (context, whereFilter = null) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let postData = new FormData();
      if (whereFilter) {
        postData.append("filter", JSON.stringify(whereFilter))
      }

      return await request.curl(context, "subscription_list", postData, process.env.VUE_APP_SUBSCRIPTION_API)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at subscriptionList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * subscriptionView
   */
  async subscriptionView (context, subscriptionId) {
    try {      
      let post_data = new FormData();
      post_data.append('subs_id', subscriptionId);
      return await request.curl(context, "subscription_view", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at subscriptionView() and Exception:",err.message)
    }    
  },

  /**
   * subscriptionAdd
   */
  async subscriptionAdd (context, subscriptionObj) {
    try{
    let post_data = new FormData();
    
    for (let key in subscriptionObj) {
      post_data.append(key, subscriptionObj[key]);
    }

    return await request.curl(context, "subscription_add", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at subscriptionAdd() and Exception:',err.message)
    }
  },

  /**
   * subscriptionEdit
   */
  async subscriptionEdit (context, subscriptionObj) {
    try{
    let post_data = new FormData();
    
    for (let key in subscriptionObj) {
      post_data.append(key, subscriptionObj[key]);
    }

    return await request.curl(context, "subscription_edit", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at subscriptionEdit() and Exception:',err.message)
    }
  },

  /**
   * subscriptionDelete
   */
  async subscriptionDelete (context, subscriptionId) {
    try{
    let post_data = new FormData();
    
    post_data.append('subs_id', subscriptionId);

    return await request.curl(context, "subscription_delete", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at subscriptionDelete() and Exception:',err.message)
    }
  },

  /**
  * user Subscription
  */
  async userSubscription (context) {
    try {    
      let post_data = new FormData();
      return await request.curl(context, "user_subscription", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at user_subscription() and Exception:",err)
    }
  },

  /**
  * userSubscriptionAllList
  */
   async userSubscriptionAllList (context) {
    try {    
      let post_data = new FormData();
      return await request.curl(context, "user_subscription_all_list", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at user_subscription() and Exception:",err.message)
    }
  },

  /**
  * subscriptionModRuleAvailableCheck
  */
  async subscriptionModRuleAvailableCheck (context, checkObj) {
    try {    
      let post_data = new FormData();
      for (let key in checkObj) {
        if (checkObj[key]) {
          post_data.append(key, checkObj[key]);
        }
      }

      return await request.curl(context, "subs_module_rule_available_check", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at user_subscription() and Exception:",err.message)
    }
  },

  /**
   *  subscription report
   */
   async subscriptionReport (context, payload, whereFilter = null) {
    try{
      let post_data = new FormData();
      for (const key in payload) {
        if (payload[key]) {
          post_data.append(key, payload[key])
        }
      }

      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }
      
      return await request.curl(context, "subscription_report", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at subscriptionReport() and Exception:',err.message)
    }
  }

}


export {
  Subscriptions
}
