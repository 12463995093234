/*eslint-disable */
// import MessageModel from '../../Model/Message'
import request from '../../Utils/curl'

let Messages = {
  /**
   * messageList
   */
  async messageList (context, admissionId, currentPage) {
    try{
      let post_data = new FormData();
      post_data.append("admId", admissionId)
      post_data.append("currentPage", currentPage)

      return await request.curl(context, "message_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at messageList() and Exception:',err.message)
    }
  },

  /**
   * messageView
   */
  async messageView (context, messageId) {
    try {      
      let post_data = new FormData();
      post_data.append('msg_id', messageId);
      return await request.curl(context, "message_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at messageView() and Exception:",err.message)
    }    
  },

  /**
   * messageAdd
   */
  async messageAdd (context, messageObj) {
    try{
      let post_data = new FormData();
    
      for (let key in messageObj) {
        post_data.append(key, messageObj[key]);
      }

      return await request.curl(context, "message_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at messageAdd() and Exception:',err.message)
    }
  },

  /**
   * messageEdit
   */
  async messageEdit (context, messageObj) {
    try{
      let post_data = new FormData();
    
      for (let key in messageObj) {
        post_data.append(key, messageObj[key]);
      }

      return await request.curl(context, "message_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at messageEdit() and Exception:',err.message)
    }
  },

  /**
   * messageDelete
   */
  async messageDelete (context, messageId) {
    try{
      let post_data = new FormData();
      
      post_data.append('msg_id', messageId);

      return await request.curl(context, "message_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at messageDelete() and Exception:',err.message)
    }
  }
}

export {
  Messages
}
